<template>
  <div id="teacher-standards">
      <b-container class="base-container-x">
        <div v-if="loading === true" class="col-12">
          <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
        </div>
        <div v-if="loading === false" id="banner" class="banner-inner teachers">
          <!-- <div class="slideshow_caption">
            <div class="slideshow_inner_caption">
              <div class="slideshow_align_caption">
                <h1 class="section-header-title text-uppercase">{{ $t('EYEP') }}</h1>
                <h3 class="article-content">{{ $t('Exchange Yoga Experience Program (WYA Continuous Yoga Education)') }}</h3>
              </div>
            </div>
          </div> -->
        </div>
        <div v-if="loading === true" class="col-12 mt-4">
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="55%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
          <br/>
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="55%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
        </div>
        <div v-if="loading === false" class="col-12 mt-4 mb-5 pt-4">
          <div class="d-flex justify-content-center align-items-center">
            <h2 class="text-center font-weight-bold">{{ $t('Teachers Standards:') }}</h2>
          </div>
          <hr>
          <p>{{ $t('Since 2011, World Yoga Alliance® has been working on sharing the passion of Traditional Yoga knowledge as well as supporting all other yoga styles to create a healthy living community worldwide. We offer credentials to yoga teachers who have successfully completed yoga training with sufficient teaching experience that meets or exceeds our standards. We always make sure that every teacher registered with us is well-qualified.') }}</p>
          <p>{{ $t('We also welcome and have a special consideration for established yoga teachers without formal certifications who have substantial teaching experience or are well-qualified yoga degree holders from any Indian University, with a minimum of 4 – 6 years teaching experiences in strong base of any of the following topics: Yoga Anatomy, Philosophy, Ayurveda and Naturopathy, Therapy practice and teaching.') }}</p>
          <p>{{ $t('Upon completion of the teacher registration, the teacher profile will be officially listed on our') }} “<router-link :to="{ name:'searchDirectory', query: { type: 'teacher' } }">{{ $t('International Yoga Teachers Directory') }}</router-link>” {{ $t('with lifetime support from the organization.') }}</p>
          <h5 class="mt-5">{{ $t('Teacher Registration Hour Standards & Education Levels:') }}</h5>
          <b-table-simple caption-top responsive bordered class="table-default mt-3 levels-table">
            <b-thead>
              <b-tr>
              <b-th variant="light" >{{ $t('Additional') }}</b-th>
              <b-th variant="light" >{{ $t('Level 1')}} </b-th>
              <b-th variant="light" >{{ $t('Level 2')}} </b-th>
              <b-th variant="light" >{{ $t('Level 3')}} </b-th>
              <b-th variant="light" >{{ $t('Level 4')}} </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
              <b-td>{{ $t('50 Hours') }}</b-td>
              <b-td>{{ $t('200 Hours') }}</b-td>
              <b-td>{{ $t('300 Hours') }}</b-td>
              <b-td>{{ $t('500 Hours') }}</b-td>
              <b-td>{{ $t('1000 Hours') }}</b-td>
              </b-tr>
              <b-tr>
              <b-td>{{ $t('100 Hours') }}</b-td>
              <b-td>{{ $t('250 Hours') }}</b-td>
              <b-td>{{ $t('400 Hours') }}</b-td>
              <b-td>{{ $t('600 Hours') }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div id="RTY" class="mt-5">
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead class="d-table-header-group d-sm-none">
                <b-tr>
                  <b-th class="text-center">
                    <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/RTY.png'" />
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td rowspan="2" class="w-25 d-none d-sm-table-cell">
                    <b-img class="w-100" :src="PUBLIC_PATH + 'img/certificate/RTY.png'" />
                  </b-td>
                  <b-td >
                    <h5>{{ $t('Registered Teacher of Yoga') }}</h5>
                    <p>{{ $t('Teachers can register as a RTY 200 if they have successfully completed a 200-hour yoga teacher training that is registered or Certified with WYA - World Yoga Alliance. All training hours must come from the same school, and multiple additional trainings cannot be combined to meet the 200-hour requirement.') }}</p>
                    <p class="font-weight-bold">{{ $t('Training Requirements') }}</p>
                    <p>✔️ {{ $t('Completed a 200-hour teacher training with a RSY 200') }}</p>
                    <p class="font-weight-bold">{{ $t('Teaching Requirements') }}</p>
                    <p>✔️ {{ $t('None') }}</p>
                    <p class="font-weight-bold">{{ $t('Requirement for Other Levels Registration') }}</p>
                    <p>✔️ {{ $t('Get Level 2, 3 or 4 registration, after complete each training or levels.') }}</p>
                    <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RTY' }}" class="px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply for Teacher License') }}</b-button></div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div id="ERTY" class="mt-5">
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead class="d-table-header-group d-sm-none">
                <b-tr>
                  <b-th class="text-center">
                    <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/ERTY.png'" />
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td rowspan="2" class="w-25 d-none d-sm-table-cell">
                    <b-img class="w-100" :src="PUBLIC_PATH + 'img/certificate/ERTY.png'" />
                  </b-td>
                  <b-td >
                    <h5>{{ $t('Experienced Registered Teacher of Yoga') }}</h5>
                    <p>{{ $t('This is a license for certified teachers who have a minimum 3 years and 2,000 practice teaching hours completed. The practice teaching must be acquired through the same school that they graduated from or from any WYA certified school from around the world. This license allows teachers to become a lead trainer of 200 Hour YTTC through WYA.') }}</p>
                    <p class="font-weight-bold">{{ $t('Training Requirements') }}</p>
                    <p>✔️ {{ $t('Completed a 200-hour or 300-hour teacher training with a RSY 200 or WYA Certified School.') }}</p>
                    <p class="font-weight-bold">{{ $t('Teaching Requirements') }}</p>
                    <p>✔️ {{ $t('Has at least 2,000 hours of teaching experience since completing training with a RSY 200 or WYA Certified School.') }}</p>
                    <p>✔️ {{ $t('Has taught for at least three years since completing training with a RSY 200 or WYA Certified School.') }}</p>
                    <p class="font-weight-bold">{{ $t('Requirement for Other Levels Registration') }}</p>
                    <p>✔️ {{ $t('Get Level 2, 3 or 4 registration, after complete each training or levels.') }}</p>
                    <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RTY' }}" class="px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply for Teacher License') }}</b-button></div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div id="AYT" class="mt-5">
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead class="d-table-header-group d-sm-none">
                <b-tr>
                  <b-th class="text-center">
                    <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/AYT.png'" />
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td rowspan="2" class="w-25 d-none d-sm-table-cell">
                    <b-img class="w-100" :src="PUBLIC_PATH + 'img/certificate/AYT.png'" />
                  </b-td>
                  <b-td >
                    <h5>{{ $t('Aerial Yoga Teacher') }}</h5>
                    <p>{{ $t('Teachers can register as a AYT Standard if they have successfully completed a 200-hour or 250-hour Aerial yoga teacher training that is registered or Certified with WYA - World Yoga Alliance. All training hours must come from the same school, and multiple additional trainings cannot be combined to meet the aerial yoga teacher registration requirement.') }}</p>
                    <p class="font-weight-bold">{{ $t('Training Requirements') }}</p>
                    <p>✔️ {{ $t('Completed a Level 1 (200-hour or 250-hour) Aerial yoga teacher training with a RSY 200 or WYA Certified School.') }}</p>
                    <p class="font-weight-bold">{{ $t('Teaching Requirements') }}</p>
                    <p>✔️ {{ $t('None') }}</p>
                    <p class="font-weight-bold">{{ $t('Requirement for Other Levels Registration') }}</p>
                    <p>✔️ {{ $t('Get Level 2, 3 or 4 registration, after complete each training or levels.') }}</p>
                    <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RTY' }}" class="px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply for Teacher License') }}</b-button></div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div id="KYT" class="mt-5">
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead class="d-table-header-group d-sm-none">
                <b-tr>
                  <b-th class="text-center">
                    <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/KYT.png'" />
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td rowspan="2" class="w-25 d-none d-sm-table-cell">
                    <b-img class="w-100" :src="PUBLIC_PATH + 'img/certificate/KYT.png'" />
                  </b-td>
                  <b-td >
                    <h5>{{ $t('Kids Yoga Teacher') }}</h5>
                    <p>{{ $t('Teachers can register as a KYT Standard if they have successfully completed both a 200-hour yoga teacher training and a 50-hour or 100-hour children\'s yoga teacher training—in either order—that are registered with World Yoga Alliance. Applicants must submit 65 teaching hours in children\’s yoga which must be performed after completing a training with a Registered Kid\’s Yoga School (KYS).') }}</p>
                    <p class="font-weight-bold">{{ $t('Training Requirements') }}</p>
                    <p>✔️ {{ $t('Completed a 200-hour yoga teacher training with a RSY 200 or WYA Certified School.') }}</p>
                    <p>✔️ {{ $t('Completed a 100-hour children\'s yoga teacher training with a Registered or Certified Kid\’s Yoga School (KYS)') }}</p>
                    <p class="font-weight-bold">{{ $t('Teaching Requirements') }}</p>
                    <p>✔️ {{ $t('Has at least 65-hours of teaching experience in children’s yoga since completing training with Kid’s Yoga School (KYS)') }}</p>
                    <p class="font-weight-bold">{{ $t('Requirement for Other Levels Registration') }}</p>
                    <p>✔️ {{ $t('Get Level 2, 3 or 4 registration, after complete each training or levels.') }}</p>
                    <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RTY' }}" class="px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply for Teacher License') }}</b-button></div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div id="PYT" class="mt-5">
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead class="d-table-header-group d-sm-none">
                <b-tr>
                  <b-th class="text-center">
                    <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/PYT.png'" />
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td rowspan="2" class="w-25 d-none d-sm-table-cell">
                    <b-img class="w-100" :src="PUBLIC_PATH + 'img/certificate/PYT.png'" />
                  </b-td>
                  <b-td >
                    <h5>{{ $t('Prenatal Yoga Teacher') }}</h5>
                    <p>{{ $t('Teachers can register as a PYT Standard if they have successfully completed both a 200-hour yoga teacher training and a 50-hour or 100-hour prenatal yoga training—in either order—that are registered with World Yoga Alliance. Applicants must submit 65 teaching hours in prenatal yoga, which must be performed after completing a training with a Registered Prenatal Yoga School (PYS).') }}</p>
                    <p class="font-weight-bold">{{ $t('Training Requirements') }}</p>
                    <p>✔️ {{ $t('Completed a 200-hour teacher training with a RSY 200 or Certified Prenatal Yoga School (PYS)') }}</p>
                    <p>✔️ {{ $t('Completed a 100-hour teacher training with a Registered or Certified Prenatal Yoga School (PYS)') }}</p>
                    <p class="font-weight-bold">{{ $t('Teaching Requirements') }}</p>
                    <p>✔️ {{ $t('Has at least 65-hours of teaching experience in prenatal yoga since completing training with Prenatal Yoga School (PYS)') }}</p>
                    <p class="font-weight-bold">{{ $t('Requirement for Other Levels Registration') }}</p>
                    <p>✔️ {{ $t('Get Level 2, 3 or 4 registration, after complete each training or levels.') }}</p>
                    <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RTY' }}" class="px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply for Teacher License') }}</b-button></div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div id="YTT" class="mt-5">
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead class="d-table-header-group d-sm-none">
                <b-tr>
                  <b-th class="text-center">
                    <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/YTT.png'" />
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td rowspan="2" class="w-25 d-none d-sm-table-cell">
                    <b-img class="w-100" :src="PUBLIC_PATH + 'img/certificate/YTT.png'" />
                  </b-td>
                  <b-td >
                    <h5>{{ $t('Yoga Therapy Teacher') }}</h5>
                    <p>{{ $t('Teachers can register as a YTT Standard if they have successfully completed both a 200-hour yoga teacher training and a 50-hour or 100-hour therapy yoga training—in either order—that are registered with World Yoga Alliance. Applicants must submit 65 teaching hours in therapy yoga, which must be performed after completing a training with a Registered Yoga Therapy School (YTS).') }}</p>
                    <p class="font-weight-bold">{{ $t('Training Requirements') }}</p>
                    <p>✔️ {{ $t('Completed a 200-hour yoga therapy teacher training with a RSY 200 or Certified Yoga Therapy School (YTS)') }}</p>
                    <p>✔️ {{ $t('Completed a 100-hour teacher training with a Registered or Certified Yoga Therapy School (YTS) In case, If applicant haven\'t completed the 200-hour Therapy teacher training but successfully completed other 200-hour yoga teacher training course – (Optional)') }}</p>
                    <p class="font-weight-bold">{{ $t('Teaching Requirements') }}</p>
                    <p>✔️ {{ $t(' Has at least 65-hours of teaching experience in yoga therapy since completing training with Yoga Therapy School (YTS)') }}</p>
                    <p class="font-weight-bold">{{ $t('Requirement for Other Levels Registration') }}</p>
                    <p>✔️ {{ $t('Get Level 2, 3 or 4 registration, after complete each training or levels.') }}</p>
                    <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RTY' }}" class="px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply for Teacher License') }}</b-button></div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div id="AVT" class="mt-5">
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead class="d-table-header-group d-sm-none">
                <b-tr>
                  <b-th class="text-center">
                    <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/AVT.png'" />
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td rowspan="2" class="w-25 d-none d-sm-table-cell">
                    <b-img class="w-100" :src="PUBLIC_PATH + 'img/certificate/AVT.png'" />
                  </b-td>
                  <b-td >
                    <h5>{{ $t('Ayurveda Teacher') }}</h5>
                    <p>{{ $t('Teachers can register as an AVT Standard if they have successfully completed a 200-hour Ayurveda teacher training that is registered or Certified with WYA - World Yoga Alliance. All training hours must come from the same school, and multiple additional trainings cannot be combined to meet the 200-hour Ayurveda teacher registration requirement. Applicants must submit 65 teaching hours in Ayurveda practice, which must be performed after completing a training with a Registered Ayurveda School (AVS).') }}</p>
                    <p class="font-weight-bold">{{ $t('Training Requirements') }}</p>
                    <p>✔️ {{ $t('Completed a 200-hour Ayurveda teacher training with an AVS 200 or Certified Ayurveda School (AVS)') }}</p>
                    <p>✔️ {{ $t('Completed a minimum 100-hour Ayurveda teacher training with a Registered or Certified Ayurveda School (AVS) In case, If applicant haven\'t completed the 200-hour Ayurveda teacher training but successfully completed other 200-hour yoga teacher training course – (Optional)') }}</p>
                    <p class="font-weight-bold">{{ $t('Teaching Requirements') }}</p>
                    <p>✔️ {{ $t('Has at least 65-hours of teaching experience in Ayurveda practice since completing training with Ayurveda School (AVS)') }}</p>
                    <p class="font-weight-bold">{{ $t('Requirement for Other Levels Registration') }}</p>
                    <p>✔️ {{ $t('Get Level 2, 3 or 4 registration, after complete each training or levels.') }}</p>
                    <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RTY' }}" class="px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply for Teacher License') }}</b-button></div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div id="YYT" class="mt-5">
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead class="d-table-header-group d-sm-none">
                <b-tr>
                  <b-th class="text-center">
                    <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/YYT.png'" />
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td rowspan="2" class="w-25 d-none d-sm-table-cell">
                    <b-img class="w-100" :src="PUBLIC_PATH + 'img/certificate/YYT.png'" />
                  </b-td>
                  <b-td >
                    <h5>{{ $t('Yin Yoga Teacher') }}</h5>
                    <p>{{ $t('Teachers can register as a YYT Standard if they have successfully completed both a 200-hour yoga teacher training and a 50-hour or 100-hour Yin yoga teacher training—in either order—that are registered with World Yoga Alliance. Applicants must submit 65 teaching hours in Yin yoga which must be performed after completing a training with a Registered Yin Yoga School (YYS).') }}</p>
                    <p class="font-weight-bold">{{ $t('Training Requirements') }}</p>
                    <p>✔️ {{ $t('Completed a 200-hour yoga teacher training with a RSY 200 or WYA Certified School.') }}</p>
                    <p>✔️ {{ $t('Completed a 50-hour or 100-hour Yin yoga teacher training with a Registered or Certified Yin Yoga School (YYS)') }}</p>
                    <p class="font-weight-bold">{{ $t('Teaching Requirements') }}</p>
                    <p>✔️ {{ $t('Has at least 65-hours of teaching experience in Yin yoga since completing training with Yin Yoga School (YYS)') }}</p>
                    <p class="font-weight-bold">{{ $t('Requirement for Other Levels Registration') }}</p>
                    <p>✔️ {{ $t('Get Level 2, 3 or 4 registration, after complete each training or levels.') }}</p>
                    <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RTY' }}" class="px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply for Teacher License') }}</b-button></div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div id="MST" class="mt-5">
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead class="d-table-header-group d-sm-none">
                <b-tr>
                  <b-th class="text-center">
                    <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/MST.png'" />
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td rowspan="2" class="w-25 d-none d-sm-table-cell">
                    <b-img class="w-100" :src="PUBLIC_PATH + 'img/certificate/MST.png'" />
                  </b-td>
                  <b-td >
                    <h5>{{ $t('Meditation & Spiritual Teacher') }}</h5>
                    <p>{{ $t('Teachers can register as a MST Standard if they have successfully completed a 200-hour Meditation & Spiritual teacher training that is registered or Certified with WYA - World Yoga Alliance. All training hours must come from the same school, and multiple additional trainings cannot be combined to meet the 200-hour Meditation & Spiritual teacher registration requirement. Applicants must submit 65 teaching hours in Meditation & Spiritual practice, which must be performed after completing a training with a Registered Meditation & Spiritual School (MSS).') }}</p>
                    <p class="font-weight-bold">{{ $t('Training Requirements') }}</p>
                    <p>✔️ {{ $t('Completed a 200-hour yoga teacher training with a RSY 200 or WYA Certified School.') }}</p>
                    <p>✔️ {{ $t('Completed a minimum 100-hour Meditation & Spiritual teacher training with a Registered or Certified Meditation & Spiritual School (MSS) In case, if applicant haven\'t completed the 200-hour Meditation & Spiritual teacher training but successfully completed other 200-hour yoga teacher training course – (Optional)') }}</p>
                    <p class="font-weight-bold">{{ $t('Teaching Requirements') }}</p>
                    <p>✔️ {{ $t('Has at least 65-hours of teaching experience in yoga since completing training with Meditation & Spiritual School (MSS)') }}</p>
                    <p class="font-weight-bold">{{ $t('Requirement for Other Levels Registration') }}</p>
                    <p>✔️ {{ $t('Get Level 2, 3 or 4 registration, after complete each training or levels.') }}</p>
                    <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RTY' }}" class="px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply for Teacher License') }}</b-button></div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div id="CYT" class="mt-5">
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead class="d-table-header-group d-sm-none">
                <b-tr>
                  <b-th class="text-center">
                    <b-img class="w-50" :src="PUBLIC_PATH + 'img/certificate/CYT.png'" />
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td rowspan="2" class="w-25 d-none d-sm-table-cell">
                    <b-img class="w-100" :src="PUBLIC_PATH + 'img/certificate/CYT.png'" />
                  </b-td>
                  <b-td >
                    <h5>{{ $t('Certified Yoga Teacher') }}</h5>
                    <p>{{ $t('To become a WYA Certified Yoga Teacher, you must complete the teacher training in any subject with WYA Certified Yoga School (CYS) where they provide WYA certificate upon completion of the course with valid WYA teacher ID Number.') }}</p>
                    <p>{{ $t('To identify Certified Yoga School, please refer to') }} <router-link :to="{ name: 'searchDirectory' }">{{ $t('WYA School Directory') }}</router-link>  {{ $t('There will be “CYS logo” on the school profile that provide WYA certificate for the training. We recommend you to confirm with the school directly before participating in any trainings.') }}</p>
                    <p class="font-weight-bold">{{ $t('Training Requirements') }}</p>
                    <p>✔️ {{ $t('Completed a 200-hour yoga teacher training with a CYS Member.') }}</p>
                    <p>✔️ {{ $t('Completed a minimum 50-hour or 100-hour teacher training in any subject with a Certified School (CYS).') }}</p>
                    <p class="font-weight-bold">{{ $t('Teaching Requirements') }}</p>
                    <p>✔️ {{ $t('None') }}</p>
                    <p class="font-weight-bold">{{ $t('Benefits') }}</p>
                    <p>✔️ {{ $t('Our CYT will received') }} <router-link :to="{ name: 'EYEP' }">{{ $t('EYEP Standards') }}</router-link></p>
                    <p>{{ $t('Other Benefits for CYT Members') }} – <router-link :to="{ name: 'memberBenefit', hash:'#teachers' }">{{ $t('Click Here') }}</router-link></p>
                    <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RTY' }}" class="px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply for Teacher License') }}</b-button></div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
      </b-container>
  </div>
</template>

<script>
export default {
  name: 'TeachersStandards',
  title: ' | Teachers Standards',
  data () {
    return {
      loading: false
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
    console.log('hash', this.$route.hash)
    setTimeout(() => {
      this.scrollToElement(this.$route.hash)
    }, 1000)
  },
  methods: {
    scrollToElement (hash) {
      const id = hash.slice(1)
      console.log('id', id)
      const banner = document.getElementById('banner').offsetHeight
      const element = document.getElementById(id)
      const elementOffset = element.offsetTop + banner - 105
      console.log('elementOffset', elementOffset)
      window.scroll({ top: elementOffset, behavior: 'smooth' })
    },
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>

<style scoped>
  #teacher-standards {
  /* Lock Text */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
</style>
